import HeapDriver from '@grininc/analytics-js/dist/drivers/heap';
import GoogleAnalyticsDriver from '@grininc/analytics-js/dist/drivers/google-analytics';
import Analytics from '@grininc/analytics-js/dist';

const pushGoogleIdentityTraits = () => {
    var $identifyTraits;
    if (window.SERVER_DATA.contact) {
        $identifyTraits = {
            contact_id: window.SERVER_DATA.contact.id
        };
        if (window.SERVER_DATA.account) {
            $identifyTraits['account_id'] = window.SERVER_DATA.account.id;
            $identifyTraits['customer_id'] = window.SERVER_DATA.account.customer_id;
            $identifyTraits['subscription_id'] = window.SERVER_DATA.account.subscription_id;
        }
    } else if (window.SERVER_DATA.account) {
        $identifyTraits = {
            account_id: window.SERVER_DATA.account.id,
            customer_id: window.SERVER_DATA.account.customer_id,
            subscription_id: window.SERVER_DATA.account.subscription_id
        };
    }

    window.dataLayer.push($identifyTraits);
};

const identify = (driver) => {
    if (window.SERVER_DATA.creator_id) {
        return driver.identify('creator:' + window.SERVER_DATA.creator_id);
    }
    if (window.SERVER_DATA.contact) {
        return driver.identify('contact:' + window.SERVER_DATA.contact.id);
    }
};

/**
 * Set up Analytics
 */
export default function () {
    window.grinAnalytics = new Analytics([]);

    if (window.SERVER_ANALYTICS.gtmKey) {
        GoogleAnalyticsDriver.bootstrap(window.SERVER_ANALYTICS.gtmKey);
        if (window.dataLayer) {
            var googleDriver = new GoogleAnalyticsDriver(window);
            window.grinAnalytics.drivers.push(googleDriver);
            identify(googleDriver);

            pushGoogleIdentityTraits();
        }
    }
    if (window.SERVER_ANALYTICS.heapAppId) {
        HeapDriver.bootstrap(window.SERVER_ANALYTICS.heapAppId);
        if (window.heap) {
            window.heap.onReady(function () {
                var heapDriver = new HeapDriver(window.heap);
                window.grinAnalytics.drivers.push(heapDriver);
                identify(heapDriver);
            });
        }
    }

    return {
        trackEvent: window.grinAnalytics.trackEvent,
        isSupportedEvent: window.grinAnalytics.isSupportedEvent
    };
}
